import React, {useEffect, useState} from 'react';
import useRaf from '@rooks/use-raf';
import styled from 'styled-components';
import {useI18next, Trans, Helmet} from 'gatsby-plugin-react-i18next';
import {styles, media, links} from 'global.config';
import Stars from 'components/Stars';
import CurvedBorder from 'components/CurvedBorder';
import AsSeenOn from 'components/Alternatives/AsSeenOn';
import Button from 'components/Button';
import TopSectionImage from 'images/email-marketing-sending-statistics.svg';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled.div`
  padding: 135px ${styles.containerPadding} 70px;
  position: relative;
  min-height: calc(100vh - 170px);
  margin: auto;
  max-width: ${styles.containerWidth};

  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionSummary = styled.div`
  font-size: 16px;
  line-height: 1.43;
  max-width: 700px;
  margin: 20px auto 40px;
  font-weight: 300;
  p {
    :first-child {
      font-size: 14px;
    }
    margin: 0;
  }
`;

const IndexSectionImage = styled.img`
  display: block;
  margin: 0 auto;
  @media ${media.mobile} {
    width: 235px;
    margin-bottom: 20px;
  }
`;

const IndexSectionTitle = styled.strong`
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding: 5px 7px 10px;
  font-weight: 300;
  border-bottom: 1px solid #fff;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 68px;
    background: #fff;
    left: -17px;
  }
  &:before {
    bottom: -5px;
    transform: rotate(-30deg);
  }
  &:after {
    bottom: 53px;
    transform: rotate(30deg);
  }
`;

const Statistics = styled.div`
  p {
    margin: 0;
  }
`;

const StatisticsItemInfo = styled.figcaption`
  margin-top: 7px;
  line-height: 1.25;
`;

const StatisticsItem = styled.figure`
  display: inline-block;
  width: auto;
  max-width: 300px;
  letter-spacing: 0;
  margin: 0 50px 50px;
  @media ${media.mobile} {
    margin: 20px 40px;
  }
`;

const StatisticsList = styled.div`
  letter-spacing: -0.3em;
  @media ${media.mobile} {
    margin-bottom: 30px;
  }
`;

const StatisticsItemNumber = styled.span`
  display: inline-block;
  font-size: 70px;
  font-weight: 500;
  line-height: 1;
  @media ${media.tablet} {
    font-size: 80px;
  }
  @media ${media.mobile} {
    font-size: 60px;
  }
`;

const StatisticsItemDay = styled(StatisticsItemNumber)`
  display: inline-block;
  text-align: left;
  width: 6em;
`;

const StatisticsItemMonth = styled(StatisticsItemNumber)``;

const BASE_DATE = 1496059824441; // 29 June '17
const BASE_EMAILS_PER_DAY = 2223397;
const BASE_EMAILS_PER_MONTH = 70442859;
const GROW_PER_DAY = 0.01; // 1%
const GROW_PER_MONTH = 0.3; // 30%
const MS_IN_DAY = 1000 * 60 * 60 * 24;
const MS_IN_MONTH = 30.42 * MS_IN_DAY;
const EM_WIDTH = 2;

function addCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const Page: React.FC = ({}) => {
  const {t} = useI18next();
  const [emailsMonth, setEmailsMonth] = useState(831225736);
  const [emailsDay, setEmailsDay] = useState(285474);
  useEffect(() => {
    //Setting monthly count
    const now = new Date();
    const prevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 0, 0, 0, 0).getTime();
    const monthsSinceBaseDate = Math.max(0, Math.ceil((prevMonth - BASE_DATE) / MS_IN_MONTH));
    setEmailsMonth(
      addCommas(Math.round(BASE_EMAILS_PER_MONTH * (1 + monthsSinceBaseDate * GROW_PER_MONTH)))
    );
  });
  useRaf(() => {
    //Settings today's count
    const now = new Date();
    const todayMidnight = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0
    ).getTime();
    const dailyProgress = (now.getTime() - todayMidnight) / MS_IN_DAY;
    const daysSinceBaseDate = Math.floor((now.getTime() - BASE_DATE) / MS_IN_DAY);
    const willSendToday = BASE_EMAILS_PER_DAY * (1 + daysSinceBaseDate * GROW_PER_DAY);
    setEmailsDay(addCommas(Math.round(willSendToday * dailyProgress)));
  }, true);
  return (
    <>
      <Helmet title={t('Email Marketing Statistics')}>
        <meta
          name="description"
          content={t(
            "Take a look at MoonMail's real time Email Marketing Statistics. Want to see more? Sign up!"
          )}
        />
      </Helmet>
      <div className="index-page">
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <div>
                <IndexSectionImage
                  src={TopSectionImage}
                  alt={t('Email Marketing Statistics')}
                  title={t('Email Marketing Statistics')}
                  width="73"
                  height="120"
                />
                <IndexSectionTitle>
                  <Trans>Hi, I'm Dave.</Trans>
                </IndexSectionTitle>
              </div>
              <IndexSectionSummary>
                <Trans>
                  <p>I'm the head of performance and email delivery within MoonMail.</p>
                  <p>Wanna see some real time email delivery data by MoonMail?</p>
                </Trans>
              </IndexSectionSummary>
              <Statistics>
                <p>
                  <Trans>MoonMail has sent</Trans>
                </p>
                <StatisticsList>
                  <StatisticsItem>
                    <StatisticsItemDay>{emailsDay}</StatisticsItemDay>
                    <StatisticsItemInfo>
                      <Trans>
                        <p>emails</p>
                        <p>today</p>
                      </Trans>
                    </StatisticsItemInfo>
                  </StatisticsItem>
                  <StatisticsItem>
                    <StatisticsItemMonth>{emailsMonth}</StatisticsItemMonth>
                    <StatisticsItemInfo>
                      <Trans>
                        <p>emails</p>
                        <p>in the previous month</p>
                      </Trans>
                    </StatisticsItemInfo>
                  </StatisticsItem>
                </StatisticsList>
                <p>
                  <Button
                    href={links.demo}
                    title={t('Log in to see more Email Marketing Statistics')}>
                    {t('Impressed? Become a Statistic!')}
                  </Button>
                </p>
              </Statistics>
            </IndexSectionContainer>
            <CurvedBorder />
          </IndexSection>
        </Stars>
        <AsSeenOn />
      </div>
    </>
  );
};

export default Page;
